<template>
  <div>
    <div class="card p-2 rounded-md mt-2">
      <div class="w-full flex justify-end" v-if="activacionMasiva">
        <div class="field grid w-full my-2">
            <label class="text-gray-600" for="usuario">{{ capitalize(tipoMasiva) }}s</label>
            <InputText v-model="itemMasiva" id="usuario" type="username" @keyup.enter="filtrarMasivo" />
        </div>
        <Button label="Cancelar" @click="limpiarFiltros" class="p-button-danger mx-2 mb-2 mt-7" />
        <Button label="Buscar" @click="filtrarMasivo" class="p-button-success mb-2 mt-7" />
      </div>
      <div class="flex w-full">
        <div v-if="activacionMasiva" class="bg-white rounded-md flex flex-wrap mb-2 w-full">
          <div class="mt-2" v-for="(seleccionada, o) in filtros[tipoMasiva]" :key="o">
            <div
              class="p-1 mx-1 text-xs flex items-center w-fit inline text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
              role="alert">
              <button @click="borrarItemMasiva(o)">
                <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                  </path>
                </svg>
              </button>
              {{ seleccionada }}
            </div>
          </div>
        </div>
        <div class="ml-2">
          <Button @click="displayModalMasiva = true" v-if="seleccionadosTabla.length" label="Activar/Inactivar" />
        </div>
      </div>
      <Dialog header="Activación/Desactivación masiva" v-model:visible="displayModalMasiva" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
          <div>
            <div class="mb-4">
              <div class="flex mb-4">
                <p v-if="envioEstadoMasivo" class="font-bold">Activar todos</p>
                <p v-else class="font-bold">Inactivar todos</p>
                <InputSwitch class="ml-2" v-model="envioEstadoMasivo" />
              </div>
              <DataTable :value="seleccionadosTabla" responsiveLayout="scroll">
                  <Column headerClass="text-xs" field="persona.full_name" header="Nombre"></Column>
                  <Column headerClass="text-xs" field="user_name" header="Usuario"></Column>
                  <Column headerClass="text-xs" field="persona.number_document" header="Cédula"></Column>
              </DataTable>
            </div>
          </div>
          <template #footer>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
              <Button label="Cancelar" icon="pi pi-times" @click="displayModalMasiva = false" class="bg-gray-600 border-0"/>
              <Button label="Guardar" icon="pi pi-check" @click="actualizarMasivo" autofocus />
            </div>
          </template>
      </Dialog>
      <DataTable
        :value="listarUsuarios"
        class="p-datatable-customers p-datatable-sm"
        dataKey="id"
        filterDisplay="menu"
        responsiveLayout="scroll"
        @sort="sort"
        :globalFilterFields="['full_name', 'email']"
        :rowClass="rowClass"
        v-model:selection="seleccionadosTabla"
      >
        <template #empty>
          No existen usuarios registradas
        </template>
        <Column headerStyle="width: 3rem;" class="rounded-l-lg shadow-sm border-r-2 text-xs" style="text-align: center; justify-content: center;" field="id" header="Imagen">
          <template #body="{data}">
            <div class="w-8 h-8 flex-none image-fit relative" v-if="data.persona.imagen">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full  w-full h-full"
                :src="data.persona.imagen ? env + data.persona.imagen : ''"
              />
            </div>
            <div class="w-8 h-8 flex-none image-fit relative" v-else>
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full  w-full h-full"
                src="../../../../../images/user.jpg"
              />
            </div>
          </template>
        </Column>
        <Column field="full_name" header="Nombre" class="my-1 py-1 shadow-sm text-xs" headerClass="text-xs">
          <template #body="{data}">
            <div class="w-full h-full">
              <p class="text-xs uppercase">
                {{ data.persona.full_name ? data.persona.full_name : '' }}
              </p>
            </div>
          </template>
        </Column>
        <Column field="user_name" header="Usuario" headerClass="text-xs" class="my-1 py-1 shadow-sm" style="min-width:10rem; color: #4A4A4A;">
          <template #body="{data}">
            <div class="w-full h-full">
              <p class="text-xs flex items-center">
                <i class="pi pi-users mr-2 mt-1"></i>
                {{ data.user_name ? data.user_name : ''}}
              </p>
            </div>
          </template>
        </Column>
        <Column field="email" header="Email" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
          <template #body="{data}">
          <p class="text-xs flex items-center">
            <i class="pi pi-envelope mr-2 mt-1"></i>
            {{data.persona.email ? data.persona.email : ''}}
          </p>
          </template>
        </Column>
        <Column header="Telefono"
          headerClass="text-xs"
          dataType="date"
          style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
          class="shadow-sm"
        >
          <template #body="{data}">
            <p class="text-xs flex items-center">
              <i class="pi pi-phone mr-2 mt-1"></i>
              {{ data.persona.telefono ? data.persona.telefono : '' }}
            </p>
          </template>
        </Column>
        <Column header="Grupo"
          headerClass="text-xs"
          dataType="date"
          style="min-width:10rem color: #4A4A4A; font: 15px/18px;"
          class="shadow-sm"
        >
          <template #body="{data}">
            <p class="text-xs">
              {{ data.grupo ? data.grupo.name : '' }}
            </p>
          </template>
        </Column>
        <Column header="Info"
          headerClass="text-xs"
          headerStyle="width: 5rem" style="text-align: center; justify-content: center;" class="shadow-sm"
        >
          <template #body="{data}">
            <button  @click="$router.push({name: 'ajustes.usuarios.ver', params: {id: data.id}})">
              <i class="pi pi-eye"></i>
            </button>
          </template>
        </Column>
        <Column header="Editar"
          v-if="$can('ajustes.usuarios.access')"
          headerClass="text-xs"
          headerStyle="width: 5rem"
          style="text-align: center; justify-content: center;"
          class="shadow-sm"
        >
          <template #body="{data}">
            <button  @click="$router.push({name: 'ajustes.usuarios.editar', params: {id: data.id}})">
              <i class="pi pi-pencil"></i>
            </button>
          </template>
        </Column>
        <Column
          style="text-align: center; justify-content: center;"
          headerStyle="width: 5rem"
        >
          <template #header>
              <div class="text-xs flex items-center">
                <p class="mr-2">
                  Estado
                </p>
                <el-popover
                  :width="200"
                  trigger="hover"
                >
                  <div class="bg-white absolute z-10 w-40 max-h-60 overflow-y-auto border rounded-md p-2">
                    <div>
                      <p class="font-medium text-xs border-b">Cambiar estado masivamente por:</p>
                      <div class="border-b" v-for="(tipo, i) in tiposBusquedaMasiva" :key="i">
                        <button @click="buscarMasivo(tipo)" class="bg-white hover:bg-blue-100 my-1 w-full p-2 text-left text-xs">
                          {{tipo}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <template #reference>
                    <button style="border: 1px solid green; padding: 0.3;" class="w-5 h-5 rounded-full justify-center items-center flex">
                      <i style="font-size: 0.5rem;" class="pi pi-plus font-bold text-green-600"></i>
                    </button>
                  </template>
                </el-popover>
                <!-- <input class="ml-2" v-if="activacionMasiva" type="checkbox"> -->
              </div>
          </template>
          <template #body="{data}">
            <div class="w-full text-center flex justify-center items-center">
              <!-- <input class="ml-2" v-if="activacionMasiva" type="checkbox"> -->
              <div class="w-2 h-2 rounded-full" :class="data.state ? 'bg-green-600' : 'bg-red-600'"></div>
            </div>
          </template>
        </Column>
        <Column v-if="activacionMasiva" selectionMode="multiple" headerStyle="width: 3em"></Column>
      </DataTable>
      <Paginator v-model:first="offset"
        v-if="!activacionMasiva"
        :rows="limit"
        :totalRecords="totalItemsCount"
        :rowsPerPageOptions="[2,10,20,30,100]"
        @page="onPage($event)"
      />
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed, watch } from 'vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import UsuariosService from '../../../../../services/usuarios.service'
import usuariosStore from '../../../../../stores/usuarios.store'
import { ElPopover } from 'element-plus/dist/index.full.js'
import Swal from 'sweetalert2'
import { capitalize } from 'lodash'
dayjs.extend(duration)
export default {
  name: 'TablaUsuarios',
  components: {
    ElPopover
  },
  setup () {
    onMounted(() => {
      getUsuarios(params.value)
    })
    // Computed
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filtros: filtros.value
      }
    })
    const filtroNombre = computed(() => usuariosStore.getters._filtro_tabla_usuarios_nombre)
    // References
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const activacionMasiva = ref(false)
    const listarUsuarios = ref([])
    const displayModalMasiva = ref(false)
    const filtros = ref({
      nombre: [],
      cedula: [],
      usuario: []
    })
    const itemMasiva = ref('')
    const tipoMasiva = ref('')
    const totalItemsCount = ref(0)
    const envioEstadoMasivo = ref(false)
    const seleccionadosTabla = ref([])
    const tiposBusquedaMasiva = ref(['cedula', 'usuario'])
    const _UsuariosService = ref(new UsuariosService())
    const env = process.env.VUE_APP_MICROSERVICE_API_USUARIOS.split('/api')[0]
    // Methods
    const getUsuarios = (parameters) => {
      _UsuariosService.value.paginate(parameters).then(({ data }) => {
        listarUsuarios.value = data.rows
        totalItemsCount.value = data.count
      })
    }
    const rowClass = (data) => {
      return data.id ? 'rowStyle' : null
    }
    const buscarMasivo = (tipo) => {
      limpiarFiltros()
      activacionMasiva.value = true
      tipoMasiva.value = tipo
    }
    const filtrarMasivo = () => {
      const dato = itemMasiva.value.split(' ')
      if (dato.length === 1) {
        if (itemMasiva.value) {
          filtros.value[tipoMasiva.value].push(itemMasiva.value)
        }
      } else {
          for (const i of dato) {
            if (i) {
              if (!filtros.value[tipoMasiva.value].find(a => a === i)) {
                filtros.value[tipoMasiva.value].push(i)
              }
            }
          }
      }
      params.value.limit = 100
      getUsuarios(params.value)
      itemMasiva.value = ''
    }
    const borrarItemMasiva = (o) => {
      filtros.value[tipoMasiva.value].splice(o, 1)
    }
    const limpiarFiltros = () => {
      filtros.value.nombre = []
      filtros.value.cedula = []
      filtros.value.usuario = []
      activacionMasiva.value = false
      params.value.limit = 10
      params.value.offset = 0
      getUsuarios(params.value)
    }
    const sort = (evt) => {
      console.log({ evt })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getUsuarios(params.value)
    }
    const actualizarMasivo = () => {
      const objeto = {
        state: envioEstadoMasivo.value,
        usuarios: seleccionadosTabla.value
      }
      _UsuariosService.value.updateAll(objeto).then(({ data }) => {
        if (data.error) {
          Swal.fire({
            title: 'Advertencia',
            text: 'Error al editar los usuarios',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          })
        } else {
          displayModalMasiva.value = false
          Swal.fire({
            title: 'Exito',
            text: 'Usuarios actualizados con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then(() => {
            limpiarFiltros()
            seleccionadosTabla.value = []
          })
        }
      })
    }
    // Watch
    watch(filtroNombre, (nuevo, viejo) => {
      if (nuevo === '') {
        filtros.value.nombre = ''
        getUsuarios(params.value)
      } else {
        activacionMasiva.value = false
        filtros.value.nombre = []
        filtros.value.nombre.push(nuevo)
        getUsuarios(params.value)
      }
    })
    return {
      totalItemsCount,
      offset,
      limit,
      sort,
      onPage,
      params,
      listarUsuarios,
      rowClass,
      filtroNombre,
      env,
      activacionMasiva,
      filtros,
      itemMasiva,
      tipoMasiva,
      tiposBusquedaMasiva,
      buscarMasivo,
      filtrarMasivo,
      limpiarFiltros,
      capitalize,
      borrarItemMasiva,
      seleccionadosTabla,
      displayModalMasiva,
      actualizarMasivo,
      envioEstadoMasivo
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
  }
  ::v-deep(.rowStyle) {
    border-radius: 5px;
    margin: 5px !important;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 4px 18px #0000001A;
    opacity: 1;
  }
  :global(.swal2-container) {z-index: 1000000 !important}
</style>
